<template>
  <a-modal :visible="payAction.off" :width="650" :title="payAction.isPay ? '新增线下支付记录' : '新增退款记录'" centered @ok="add" :confirmLoading="confirmLoading"
    @cancel="cancel()">
    <a-row>
      <a-col :span="24">
        <span>*{{ payAction.isPay ? '收款人选择：' : '退款方选择：' }}</span>
        <a-select @change="payeeChange" :value="payeeIndex" style="width: 320px">
          <a-select-option v-for="(item,index) in payeeList" :key="index">
            {{ index == 0 ? '货款' : '税款' }}：{{ item.bankAccountName }} </a-select-option>
        </a-select>
        <div class="payee-error" v-if="payeeIndex === 0 && payeeObj.supplierBankNo">*供应商默认收款银行账号未设置（卖家中心-财务管理）`</div>
          <div class="payee-error" v-if="payeeIndex === 1 && payeeObj.taxBankNo">*税款默认收款银行账号未设置`</div>
      </a-col>
    </a-row>
    <div class="card">
      <div class="after">{{ payAction.isPay ? '收款人信息' : '退款方信息' }}</div>
      <a-row>
        <a-col :span="!payeeIndex ? 24 : 12">户名：{{ payeeObj.bankAccountName }}</a-col>
        <a-col :span="12">账号：{{ payeeObj.bankAccount }}</a-col>
        <a-col :span="12">银行联行号：{{ payeeObj.bankNo }}</a-col>
        <a-col :span="12" v-if="payeeIndex">银行名称：
          <a-select show-search placeholder="请选择银行" style="width: 204px" :value="form.bankName"
            :filter-option="filterOption" @change="form.bankName = $event">
            <a-select-option v-for="item in bankList" :key="item.bankName">
              {{ item.bankName }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </div>

    <div class="card input">
      <div class="after">{{ payAction.isPay ? '本次支付' : '本次退款' }}</div>
      <a-row type="flex">
        <a-col :span="11">
          <a-checkbox :checked="form.payAmount == residue"
            @change="$event.target.checked ? (form.payAmount = residue) : (form.payAmount = 0)"
            style="line-height: 32px">
            {{ payeeIndex ? '税款应' : '货款应' }}{{ payAction.isPay? '收' : '退' }}：¥ {{ residue }}
          </a-checkbox>
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :span="3" class="text-right">{{ payAction.isPay ? '支付' : '' }}金额：</a-col>
        <a-col :span="8">
          <a-input-number style="width: 100%" :precision="2" v-model="form.payAmount" :disabled="form.payAmount == residue" @blur="onBlur"></a-input-number>
        </a-col>
        <a-col :span="3" class="text-right">缴纳时间：</a-col>
        <a-col :span="8">
          <a-date-picker style="min-width: 100%" show-time placeholder="请选择" :value="form.payTime"
            @change="pickerChange" />
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :span="3" class="text-right">{{ payAction.isPay ? '支付' : '收款' }}银行：</a-col>
        <a-col :span="8">
          <a-select v-if="payAction.off" show-search placeholder="请选择银行" style="width: 100%"
            :filter-option="filterOption" @change="form.payBank = $event">
            <a-select-option v-for="item in bankList" :key="item.bankName">
              {{ item.bankName }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3" class="text-right">支付编号：</a-col>
        <a-col :span="8">
          <a-input v-model="form.paymentNo" placeholder="请输入支付编号"></a-input>
        </a-col>
        <a-row style="width: 100%">
          <a-col :span="11">
            <span>{{ payAction.isPay ? '支付' : '收款' }}账号户名</span>
            <a-input class="col-input" v-model="form.payName" placeholder="请输入账号户名"></a-input>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="11">
            <span>{{ payAction.isPay ? '支付' : '收款' }}账号</span>
            <a-input class="col-input" v-model="form.payAccount"
              @input="form.payAccount=$event.target.value.replace(/[^\d]/g,'')" placeholder="请输入账号"></a-input>
          </a-col>
        </a-row>
      </a-row>
    </div>

    <div class="card">
      <div class="after">订单金额变化</div>
      <a-row>
        <a-col :span="12">已付货款：{{ net.paid }} （<span
            class="text-font">{{ payAction.isPay ? '+' : '-' }}{{ !payeeIndex ? form.payAmount.toFixed(2) : '0.00' }}</span>）
        </a-col>
        <a-col :span="12">已付税款：{{ tax.paid }} （<span
            class="text-font">{{ payAction.isPay ? '+' : '-' }}{{ payeeIndex ? form.payAmount.toFixed(2) : '0.00' }}</span>）
        </a-col>
      </a-row>
    </div>
    <a-upload :fileList="fileList" accept="image/*,application/pdf" :action="baseUrl + '/qiniu/upload'" :headers="{Authorization: token}" @change="uploadChange">
      <a-button>
        <a-icon type="upload" /> 上传凭证
      </a-button>
    </a-upload>
  </a-modal>
</template>

<script>
import moment from 'moment'
import cookie from '@/utils/js-cookie.js'
import {bankList,payee,add, refound}  from '@/api/pay'
export default {
  props: {
    tax:{
      type: Object,
      default: () => {}
    },
    net:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      confirmLoading:false,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      token: cookie.get('token'),
      fileList: [],
      payeeList: [],
      obj: {},
      payeeIndex: 0,
      bankList: [],
      payAction: {
        off: false,
        isPay: true
      },
      form: {
        payAmount: 0,
        payTime: moment().format('YYYY-MM-DD HH:mm'),
        payAccount: undefined,
        payName: undefined,
        payBank: undefined,
        paymentNo: undefined,
        bankName: undefined
      }
    }
  },
  created() {
    this.payee()
    bankList().then(res => {
      this.bankList = res.data
    })
  },
  computed: {
    payeeObj () {
      return this.payeeList[this.payeeIndex] || {}
    },
    residue () {
      if (!this.payAction.isPay) {
        return this.payeeIndex ? this.tax.paid : this.net.paid
      } else {
        return this.payeeIndex ? this.tax.noPay : this.net.noPay
      }
    }
  },
  methods: {
    refundChange() {
      this.payAction.isPay=false
      this.payAction.off=true
      this.payee()
    },
    offlineChange() {
      this.payAction.isPay=true
      this.payAction.off=true
      this.payee()
    },
    payee () {
      payee({ orderId: this.$route.query.id }).then(res => {
        console.log('????', res)
        if (res.code === 200) {
          const data = res.data
          this.obj = data
          this.payeeIndex = res.data ? 0 : undefined
          this.payeeList = [
            { bankAccountName: data.supplierName, bankName: data.supplierBankName, id: data.supplierId, bankAccount: data.supplierBankAccount, bankNo: data.supplierBankNo },
            { bankAccountName: data.taxRegionName, bankName: undefined, id: data.taxRegionId, bankAccount: data.taxBankAccount, bankNo: data.taxBankNo }
          ]
          this.form.payAccount = data.purchaserBankAccount
          this.form.payName = data.purchaserBankName
        }
      })
    },
    uploadChange({ fileList }) {
      if (fileList[0].type.indexOf('image') === -1 && fileList[0].type.indexOf('pdf') === -1) {
        this.$message.error('请上传正确的格式')
        this.fileList = []
        return
      }
      this.fileList = [fileList[fileList.length - 1]]
    },
    cancel() {
      this.payAction.off = false
      this.payeeList[1].bankName = undefined
      this.payeeIndex = 0
      this.fileList = []
      this.form = {
        payAmount: 0,
        payTime: moment().format('YYYY-MM-DD HH:mm'),
        payAccount: this.obj.purchaserBankAccount,
        payName: this.obj.purchaserBankName,
        payBank: undefined,
        paymentNo: undefined,
        bankName: undefined
      }
    },
    payeeChange (e) {
      console.log(e)
      this.payeeIndex = e
      this.form.payAmount = 0
    },
    pickerChange (e) {
      this.form.payTime = e.format('YYYY-MM-DD HH:mm')
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onBlur () {
      if (Number(this.form.payAmount) > Number(this.residue)) {
        this.$message.error(`支付金额不能大于税款金额！`)
      }
    },
    add() {
      const { bankNo, bankAccountName, bankAccount } = this.payeeList[this.payeeIndex]
      if (Number(this.form.payAmount) > Number(this.residue)) {
        this.$message.error(`支付金额不能大于税款金额！`)
        return
      }
      let bankName = undefined
      if (!this.payeeIndex) {
        bankName = this.obj.supplierBankName
      } else {
        bankName = this.form.bankName
      }
      const bankReceiptFile = this.fileList.length && `https://www.taxoss.zz91.cn/${this.fileList[0].response.data}` || undefined
      if (this.payeeIndex && !this.form.bankName) {
        this.$message.error(`请选择银行名称`)
        return
      }
      if (!this.form.payAmount) {
        this.$message.error(`请输入支付金额`)
        return
      }
      if (!this.form.payBank) {
        this.$message.error(`请选择${this.payAction.isPay ? '支付' : '收款'}银行`)
        return
      }
      if (!this.form.paymentNo) {
        this.$message.error(`请输入支付编号`)
        return
      }
      if (!this.form.payName) {
        this.$message.error(`请选择${this.payAction.isPay ? '支付' : '收款'}账号户名`)
        return
      }
      if (!this.form.payAccount) {
        this.$message.error(`请选择${this.payAction.isPay ? '支付' : '收款'}账号`)
        return
      }
      if (!bankReceiptFile) {
        this.$message.error(`请上传凭证`)
        return
      }
      this.confirmLoading = true
      if (this.payAction.isPay) {
        add({ ...this.form, orderId: this.$route.query.id, type: this.payeeIndex ? 1 : 2, bankNo, bankAccountName, bankAccount, bankReceiptFile, bankName }).then(res => {
          this.confirmLoading = false
          if (res.code === 200) {
            this.$message.success(res.message)
            this.$emit('init')
            this.cancel()
          } else {
            this.$message.error(res.message)
          }
        }).catch(() => {
          this.confirmLoading = false
        })
      } else {
        refound({ ...this.form, orderId: this.$route.query.id, type: this.payeeIndex ? 1 : 2, bankNo, bankAccountName, bankAccount, bankReceiptFile, bankName }).then(res => {
          this.confirmLoading = false
          if (res.code === 200) {
            this.$message.success(res.message)
            this.$emit('init')
            this.cancel()
          } else {
            this.$message.error(res.message)
          }
        }).catch(() => {
          this.confirmLoading = false
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.card {
  margin: 24px 0;
  border: 1px solid #d7d7d7;
  position: relative;
  border-radius: 2px;
  padding: 18px 18px 12px 18px;
  .after {
    position: absolute;
    color: #8400ff;
    font-weight: 700;
    font-size: 16px;
    padding: 0 8px;
    top: -12px;
    left: 34px;
    z-index: 2;
    background: #fff;
  }
  /deep/ .ant-col {
    margin-bottom: 6px;
  }
}
.input {
  /deep/ .ant-col {
    margin: 12px 0;
  }
}
.text-right {
  line-height: 32px;
  text-align: right;
}
.payee-error {
  padding-left: 90px;
  color:  red;
}
</style>