var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","visible":_vm.visible,"title":"小程序货流记录","footer":null,"width":1000},on:{"cancel":_vm.handleCancel}},[_c('a-row',{staticStyle:{"margin-bottom":"20px"}},[_c('a-col',{staticClass:"col",attrs:{"span":24}},[_c('span',[_vm._v("运输日期：")]),_c('a-range-picker',{attrs:{"format":"YYYY-MM-DD"},on:{"change":_vm.pickerChange}})],1),_c('a-col',{attrs:{"span":24}},[_c('a-divider',{staticStyle:{"margin":"10px 0"},attrs:{"dashed":""}})],1),_c('a-col',{staticClass:"col",attrs:{"span":24}},[_c('a-row',{staticStyle:{"width":"100%","line-height":"32px"},attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('a-col',[_c('span',[_vm._v("搜索：")]),_c('a-input-search',{staticStyle:{"width":"420px"},attrs:{"enter-button":"","placeholder":"可模糊搜索车牌号、吨数"},on:{"search":function($event){_vm.form.pageNum = 1;_vm.warehouseList()}},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1),_c('a-col',[_c('a-button',{attrs:{"type":"primary","loading":_vm.loadingBtn},on:{"click":_vm.warehouseBind}},[_vm._v("关联订单")])],1)],1)],1)],1),_c('a-table',{attrs:{"data-source":_vm.data,"size":"middle","columns":_vm.logisticsColumns,"rowKey":"id","loading":_vm.loading,"row-selection":{ selectedRowKeys: _vm.selectedRowKeys, onChange: _vm.onSelectChange },"pagination":{
          size: 'big',
          total: _vm.form.total,
          current: _vm.form.pageNum,
          pageSize: _vm.form.pageSize,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['5', '10', '20', '30', '40'],
          showTotal: function (total) { return ("共有 (" + (_vm.form.total) + ") 条记录 第 " + (_vm.form.pageNum) + " / " + _vm.totalPage + " 页"); }}},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"action",fn:function(text, row){return [_c('a',{on:{"click":function($event){$event.stopPropagation();_vm.warehouseInId = row.id;_vm.visiblePic=true}}},[_vm._v("查看照片")])]}}])})],1),_c('LogisticsPic',{attrs:{"visible":_vm.visiblePic,"warehouseInId":_vm.warehouseInId},on:{"cancel":function($event){_vm.visiblePic = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }