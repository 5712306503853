<template>
  <div>
    <a-card title="开票状态">
      
      <!-- 判断是否填写成本 -->
      <a-row type="flex" justify="space-between" v-if="!info.hasEditCost&&info.invoiceStatus === 'WAIT_APPLY'">
        <a-col >
          开票进度：
          <span ><a>等待卖家成本申报</a></span>
        </a-col>
        <a-col >
          <a-button @click="apply" type="primary">申请开票</a-button>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between" v-else>
        <a-col>
          开票进度：
          <span v-if="info.invoiceStatus === 'REFUSED'" class="font-error">
            <a-icon type="exclamation-circle" /> {{info.invoiceStatus | status}}
          </span>
          <a v-else>{{info.invoiceStatus | status}}{{info.invoiceStatus === 'WAIT_APPLY' && info.taxPaymentPro !== '100%' && '，请完成税款支付' || ''}}</a>
        </a-col>
        <a-col v-if="info.invoiceStatus === 'REFUSED' ||info.invoiceStatus === 'WAIT_APPLY'">
          <a-button v-if="info.invoiceStatus === 'WAIT_APPLY'" type="primary" @click="apply">
              {{info.invoiceStatus === 'REFUSED' ? '重审申请' : '申请开票'}}
            </a-button>

            <a-button v-else-if="info.invoiceStatus === 'REFUSED'" type="primary" @click="apply">重审申请</a-button>
        </a-col>
        <a-col :span="24" v-if="info.invoiceStatus == 'REFUSED'">
            开票反馈：{{info.auditOpinion}}
        </a-col>
      </a-row>
    </a-card>

    <a-card title="缴税信息">
        <a-row :gutter="16">
            <a-col :span="10">
              <span style="margin-left: 28px;">购买方： </span>
            <span class="font-hint">{{info.purchaserName}}</span></a-col>
            <a-col :span="14">纳税人识别号： {{info.purchaserCode}}</a-col>
            <a-col :span="10">
              <span class="fl">地址、电话：</span>
              <p class="col-r">
                  <span>{{info.purchaserAddress}}</span>&nbsp;
                  <span>{{info.purchaserMobile}}</span>
              </p>                
            </a-col>
            <a-col :span="14">开户行及账号：
              <a-space>
                <span>{{info.purchaserBankName}}</span>
                <span>{{info.purchaserBankAccount}}</span>
              </a-space>
            </a-col>

            <a-col :span="24"><a-divider dashed /></a-col>

            <a-col :span="10">
              <span style="margin-left: 28px;">销售方： </span>
              <span class="font-hint">{{info.supplierName}}</span></a-col>
            <a-col :span="14">纳税人识别号： {{info.supplierCode}}</a-col>
            <a-col :span="10">
              <span class="fl">地址、电话：</span>
              <p class="col-r">
                  <span>{{info.supplierAddress}}</span>&nbsp;
                  <span>{{info.supplierMobile}}</span>
              </p>
            </a-col>
            <a-col :span="14">开户行及账号：
              <a-space>
                <span>{{info.supplierBankName}}</span>
                <span>{{info.supplierBankAccount}}</span>
              </a-space>
            </a-col>

            <a-col :span="24"><a-divider dashed /></a-col>

            <a-col :span="10">本订单合计：{{typeof info.contractAmount=='number' && info.contractAmount.toFixed(2)}}</a-col>
            <a-col :span="14">本订单增值税：{{typeof info.vatTaxAmount=="number"&& info.vatTaxAmount.toFixed(2)}}</a-col>
        </a-row>
    </a-card>

    <a-card title="发票详情">
        <a-row type="flex" justify="end">
            <a-col>
                <a-space style="margin-bottom: 16px">
                    <a-button type="primary" @click="handlerDownloadExport" :loading="exportLoading">导出发票信息</a-button>
                    <a-button type="primary" @click="handlerPicDownload" :loading="picLoading" >下载发票图片</a-button>
                </a-space>
            </a-col>
            <a-col :span="24">
                <a-table :data-source="data" :columns="invoiceColumns" @change="handleTableChange"
                :pagination="{
                  size: 'big',
                  total: form.total,
                  current: form.pageNum,
                  pageSize: form.pageSize,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['5', '10', '20', '30', '40'],
                  showTotal: total => `共有 (${form.total}) 条记录 第 ${form.pageNum} / ${totalPage} 页`}"
                :rowKey="(record,index)=>{return index}"
                :loading="loading">
                    <template slot="totalAmount" slot-scope="text">
                        <span>{{text.toFixed(2)}}</span>
                    </template>
                    <template slot="vatTaxAmount" slot-scope="text">
                        <span>{{text.toFixed(2)}}</span>
                    </template>
                    <template slot="invoiceType" slot-scope="text">
                      {{ invoiceType[text] || '电子专票' }}
                    </template>
                    <template slot="action" slot-scope="text, row">
                        <a-icon type="picture" v-if="row.imgPath" style="color: #1890FF" @click="show(row)" />
                    </template>
                </a-table>
            </a-col>
        </a-row>
    </a-card>

    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <iframe v-if="previewImage.indexOf('.pdf') !== -1 " :src="previewImage " style="width:100%;height:600px;margin-top:30px;border:1px solid #f2f2f2" frameborder="0"></iframe>
      <template v-else>
        <img alt="example" style="width: 100%" :src="previewImage" />
        <a-button type="primary" style="margin-top: 16px" @click="downloadPt">下载图片</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { detail,apply,downloadExport, picDownload } from "@/api/order";
import { invoiceState, invoiceType } from "@/utils/constArr";
import { downloadIamge } from '@/api/utils'
const invoiceColumns = [
  {
    title: '开票日期',
    dataIndex: 'invoiceDate',
    align: 'left'
  },
  {
    title: '发票号',
    dataIndex: 'invoiceNo',
    align: 'left',
  },
  // {
  //   title: '商品',
  //   dataIndex: 'invoiceName',
  // },
  // {
  //   title: '数量',
  //   dataIndex: 'quantity',
  //   align: 'right',
  // },
  {
    title: '价税合计',
    dataIndex: 'totalAmount',
    scopedSlots: { customRender: 'totalAmount' },
    align: 'right',
  },
  {
    title: '增值税',
    dataIndex: 'vatTaxAmount',
    scopedSlots: { customRender: 'vatTaxAmount' },
    align: 'right',
  },
  {
    title: '发票类型',
    dataIndex: 'invoiceType',
    scopedSlots: { customRender: 'invoiceType' }
  },
  {
    title: '操作',
    dataIndex: 'imgPath',
    scopedSlots: { customRender: 'action' }
  },
]
export default {
    data() {
        return {
            invoiceColumns,
            data: [],
            info: {},
            loading: false,
            imgVisible: false,
            previewImage: '',
            invoiceNo: '',
            invoiceType,
            form: {
              pageNum: 1,
              pageSize: 5,
              total: 0
            },
            totalPage: 0,
            exportLoading:false,//下载发票信息中
            picLoading:false //下载发票图片中
        }
    },
    filters: {
      status: (val)=> invoiceState[val]
    },
    mounted() {
      this.init()
    },
    methods: {
      handlerDownloadExport () {
        this.exportLoading = true
        let orderId = this.$route.query.id
        downloadExport({ orderId: orderId }).then(res => {
            this.exportLoading = false
            console.log(res)
            if (res.type.includes('application/json')) {
              const reader = new FileReader() // 创建一个FileReader实例
              reader.readAsText(res, 'utf-8') // 读取文件,结果用字符串形式表示
              reader.onload = () => { // 读取完成后,**获取reader.result**
                const res = JSON.parse(reader.result)
                this.$message.error(res.message)
              }
            } else {
              this.downFun(res,orderId,'发票信息包')
            }
        })
      },
      handlerPicDownload () {
        if (this.data.length === 0) {
          this.$message.error('无发票信息')
          return
        }
        this.picLoading = true
        let orderId = this.$route.query.id
        picDownload({ orderId: orderId }).then(res => {
            this.picLoading = false
            console.log(res)
            if (res.type.includes('application/json')) {
              const reader = new FileReader() // 创建一个FileReader实例
              reader.readAsText(res, 'utf-8') // 读取文件,结果用字符串形式表示
              reader.onload = () => { // 读取完成后,**获取reader.result**
                const res = JSON.parse(reader.result)
                this.$message.error(res.message)
              }
            } else {
              this.downFun(res,orderId,'发票图片包')
            }
        })
      },
      downFun(res,id,name){
        // 创建一个隐藏的a连接，
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/zip' })
        link.style.display = 'none'
        // 设置连接
        link.href = URL.createObjectURL(blob)
        link.download = `订单${id}${name}` + '.zip'
        document.body.appendChild(link)
        // 模拟点击事件
        link.click()
      },
      // 初始化 获取票流详情
      init() {
        detail({orderId: this.$route.query.id, ...this.form}).then(res => {
          console.log(res)
          if (res.code === 200) {
            this.info = res.data || {}
            res.data.page && 
            (this.data = this.info.page.list, 
            this.form.total = res.data.page.total,
            this.totalPage = res.data.page.totalPage)
          } else {
            this.$message.error(res.message)
          }
        })
      },
      handleTableChange (e) {
        this.form.pageNum = e.current
        this.form.pageSize = e.pageSize
        this.init()
      },
      // 请求开票
      apply() {
        this.$confirm({
          title: `是否${this.info.invoiceStatus == 'REFUSED' ? '重审申请' : '申请开票'}?`,
          onOk: () => {
            apply({orderId: this.$route.query.id}).then(res => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.init()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        })
      },
      show(row) {
        this.previewImage = row.imgPath
        this.imgVisible = true
        this.invoiceNo = row.invoiceNo
      },
      downloadPt () { // 获取图片地址
        downloadIamge(this.bigImg, this.invoiceNo)
      }
    }
}
</script>

<style lang="less" scoped>
.font-hint {
    color: rgba(0, 0, 255, 0.6);
}
.font-error {
    color: @error;
}
.col-r {
  margin-left: 84px;
}
/deep/ .ant-divider-horizontal {
    margin: 12px 0 6px 0;
}
/deep/ .ant-card-bordered {
  border: none;
  margin-bottom: 42px;
}
/deep/ .ant-col {
    margin-bottom: 8px;
}
</style>