<template>
  <div>
    <a-modal
      centered
      :visible="visible"
      @cancel="handleCancel"
      title="小程序货流记录"
      :footer="null"
      :width="1000"
    >
      <a-row style="margin-bottom:20px">
        <a-col :span="24" class="col">
          <span>运输日期：</span>
          <a-range-picker format="YYYY-MM-DD" @change="pickerChange"/>
        </a-col>
        <a-col :span="24">
          <a-divider dashed style="margin:10px 0"/>
        </a-col>
        <a-col :span="24" class="col">
          <a-row type="flex" justify="space-between" align="middle" style="width: 100%;line-height: 32px;">
            <a-col>
              <span>搜索：</span>
              <a-input-search style="width: 420px" v-model="form.search" enter-button placeholder="可模糊搜索车牌号、吨数" @search="form.pageNum = 1;warehouseList()" />
            </a-col>
            <a-col>
              <a-button type="primary" :loading="loadingBtn" @click="warehouseBind">关联订单</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <a-table
          :data-source="data"
          size="middle"
          :columns="logisticsColumns"
          rowKey="id"
          :loading="loading"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="handleTableChange"
          :pagination="{
            size: 'big',
            total: form.total,
            current: form.pageNum,
            pageSize: form.pageSize,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['5', '10', '20', '30', '40'],
            showTotal: total => `共有 (${form.total}) 条记录 第 ${form.pageNum} / ${totalPage} 页`}">
            <template slot="action" slot-scope="text, row">
              <a @click.stop="warehouseInId = row.id;visiblePic=true">查看照片</a>
            </template>
        </a-table>
    </a-modal>
    <LogisticsPic :visible="visiblePic" :warehouseInId="warehouseInId" @cancel="visiblePic = false"></LogisticsPic>
  </div>
</template>
<script>
import LogisticsPic from './LogisticsPic.vue'
import { warehouseList, warehouseBind } from '@/api/order'
  // const carNumber = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/
const logisticsColumns = [
  {
    title: '车牌号',
    dataIndex: 'licenseNum',
    scopedSlots: { customRender: 'licenseNum' },
    align: 'left',
    width:'100px'
  },
  {
    title: '出发日期',
    dataIndex: 'deliverTime',
    scopedSlots: { customRender: 'deliverTime' },
    align: 'left',
    width:'100px'
  },
  {
    title: '到达日期',
    dataIndex: 'endTime',
    align: 'left',
    width:'100px'
  },
  {
    title: '吨数',
    dataIndex: 'netWeight',
    scopedSlots: { customRender: 'netWeight' },
    align: 'right'
  },
  {
    title: '起点',
    dataIndex: 'deliverPlaceFullAddress',
    scopedSlots: { customRender: 'deliverPlaceFullAddress' },
    align: 'left'
  },
  {
    title: '终点',
    dataIndex: 'endAddress',
    scopedSlots: { customRender: 'endAddress' },
    align: 'left'
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    width:'100px'
  }
]
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    }
  },
  components: {
    LogisticsPic
  },
  data () {
    return {
      visiblePic: false,
      logisticsColumns: logisticsColumns,
      selectedRowKeys: [],
      loading: false,
      loadingBtn: false,
      form: {
          search: null,
          endTime: null,
          beginTime: null,
          pageNum: 1,
          pageSize: 10,
          total: 0
      },
      totalPage: 0,
      data: [],
      warehouseInId: null
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.warehouseList()
      } else {
        this.form = {
          search: null,
          endTime: null,
          beginTime: null,
          pageNum: 1,
          pageSize: 10,
          total: 0
        }
        this.totalPage = 0
        this.selectedRowKeys = []
      }
    }
  },
  mounted() {
    this.warehouseList()
  },
  methods: {
    warehouseList() {
      this.loading = true
      warehouseList({orderId: this.orderId, ...this.form}).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.data = res.data.list
          this.form.total = res.data.total
          this.totalPage = res.data.totalPage
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => this.loading = false)
    },
    warehouseBind() {
      this.loadingBtn = true
      warehouseBind({inIds: this.selectedRowKeys, orderId: this.orderId}).then(res => {
        this.loadingBtn = false
        if (res.code == 200) {
          this.$message.success(res.message)
          this.selectedRowKeys = []
          this.warehouseList()
          this.$emit('ok')
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => this.loadingBtn = false)
    },
    handleCancel () {
      this.$emit('cancel')
    },
    // 批量操作
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange (e) {
      this.form.pageNum = e.current
      this.form.pageSize = e.pageSize
      this.warehouseList()
    },
    pickerChange(date, dateString) {
      this.form.beginTime = dateString[0]
      this.form.endTime = dateString[1]
      this.warehouseList()
    },
  }
}
</script>
<style lang="less" scoped>
  /deep/ .check-color {
    background: #f0f5ff;
  }
</style>
