<template>
  <div>
    <a-modal
      centered
      :visible="visible"
      @cancel="handleCancel"
      title="查看照片"
      :footer="null"
      :width="900"
    >
      <a-spin :spinning="loading">
        <a-row type="flex" :gutter="14" class="row" v-if="data.length">
          <a-col :span="6" v-for="item in data" :key="item.id">
            <div class="freight-photo">
              <img :src="item.imageUrl" alt="">
              <p class="info">{{item.typeName}}</p>
              <div class="mask" @click="show(item.imageUrl)">
                <a-space>
                  <a-icon type="eye"></a-icon>
                </a-space>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-divider v-else>暂无信息</a-divider>
      </a-spin>
    </a-modal>
    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <img alt="营业执照照片" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { warehouseImg } from '@/api/order'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    warehouseInId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.warehouseImg()
      }
    }
  },
  data () {
    return {
      previewImage: '',
      imgVisible: false,
      data: [],
      loading: false
    }
  },
  methods: {
    warehouseImg() {
      this.loading = true
      warehouseImg({warehouseInId: this.warehouseInId}).then(res => {
        if (res.code === 200) {
          this.data = res.data
        }
      }).finally(() => (this.loading = false))
    },
    handleCancel () {
      this.$emit('cancel')
    },
    show (url) {
      this.previewImage = url
      this.imgVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
  .row {
    margin-top: 32px;
    /deep/ .ant-col-6 {
        margin-bottom: 20px;
      }
  }
  .freight-photo {
  border: 1px solid #ebebeb;
  position: relative;
  .info {
    height: 40px;
    line-height: 40px;
    background: #fff;
    text-align: center;
    font-size: 16px;
    border-top: 1px solid #f2f2f2;
    margin-bottom: 0;
  }

  img {
    background: #f6f6f6;
    width: 100%;
    height: 160px;
    transition: all .2s;
  }
  .mask {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all .3s;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 160px;
    width: 100%;
    background: rgba(0, 0, 0, .4);
    font-size: 18px;
  }
  &:hover .mask{
    opacity: 1;
  }
}
</style>
